<input kendoTextBox
       type="number"
       inputmode="numeric"
       pattern="\d*"
       min="1"
       oninput="validity.valid || (value='')"
       [max]="maxRating || 12"
       [id]="id"
       [disabled]="disabled"
       [(ngModel)]="value"
       (change)="focusout()"
       (focus)="focus()"
       (focusout)="focusout()">
