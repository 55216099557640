import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {NitForm} from '@nit-core/forms';
import {Grading} from '@nit-core/models';
import {AchievementMark} from '@nit-core/models/nush-mark';
import {NitToastr} from '@nit-core/services/global/nit-toastr.service';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AddEditCommentEvent, DeleteCommentEvent, NusPopoverEvent} from '../../models/nush.models';
import {AchievementMarkType} from '@nit-core/global/domain/enums';
import {LabelDirective} from '@progress/kendo-angular-label';
import {MarkInputComponent} from './mark-input/mark-input.component';
import {CommonModule} from '@angular/common';
import {TooltipDirective} from '@progress/kendo-angular-tooltip';
import {SelectedPopoverValuePipe} from '@nit-core/pipes/selected-popover-value.pipe';

const configControls = {
  isVerbalFormed: {
    type: 'isVerbalGradingActive',
    controls: [
      {name: 'Сформовано', value: true}
    ]
  },
  customDigitalRating: {
    type: 'isDigitalGradingActive',
    controls: [
      {name: 'зв.', value: 0},
      {name: 'н/а', value: 1},
      {name: 'зарах.', value: 2},
      {name: 'вивч.', value: 3},
      {name: 'н/о', value: 4},
      {name: 'не зарах.', value: 5}
    ]
  },
  absense: {
    type: 'absense',
    controls: [
      {name: 'H', value: 0}
    ]
  },
  leveledAssessmentType: {
    type: 'isLeveledGradingActive',
    controls: [
      {name: 'П', value: 0},
      {name: 'С', value: 1},
      {name: 'Д', value: 2},
      {name: 'В', value: 3},
    ]
  }
};
export type configControlsType = typeof configControls;

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'nit-mark',
  templateUrl: './mark.component.html',
  styleUrl: './mark.component.scss',
  standalone: true,
  imports: [LabelDirective, FormsModule, ReactiveFormsModule, MarkInputComponent, CommonModule, TooltipDirective, SelectedPopoverValuePipe]
})
export class MarkComponent implements OnChanges, OnInit, OnDestroy {
  @Input() isGeneralCharacteristics: boolean = false;
  @Input() isAbsenseSupported: boolean = false;
  @Input() readOnly?: boolean;
  @Input() rowId?: string;
  @Input() colId?: string;
  @Input() markService: RestService<any>;
  @Input() additionalValues: Record<string, string | boolean> = {};
  @Input() rate?: AchievementMark = new AchievementMark();
  @Input() marksOfRow?: AchievementMark[];
  @Input() grading: Grading;
  @Input() colKey: string;
  @Input() rowKey: string;
  @Input() index?: number;
  @Input() is1To4Grade: boolean = true;
  @Input() hasComment: boolean = false;
  @Input() isAllowedWithoutRate: boolean = false;
  @Input() isOpened: boolean = false;
  @Input() generalAchievementValue?: AchievementMarkType;
  @Input() ignoreGrading: boolean = false;
  @Input() maxRating: number;

  @Output() openPopover = new EventEmitter<NusPopoverEvent>();
  @Output() submitted: EventEmitter<string> = new EventEmitter<string>();
  @Output() commentAddEditEmitted: EventEmitter<AddEditCommentEvent> = new EventEmitter<AddEditCommentEvent>();
  @Output() deleteCommentEmitted: EventEmitter<DeleteCommentEvent> = new EventEmitter<DeleteCommentEvent>();

  showMarkInput$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isMarkInputHidden: boolean = true;
  subscriptions: Subscription[] = [];

  id = 'input_' + Math.floor(Math.random() * (999 - 100 + 1) + 100);
  form: NitForm;
  configControls: configControlsType = configControls;

  constructor(private readonly _elementRef: ElementRef,
              private readonly _toast: NitToastr
  ) {
    this._initForm();
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target): void {
    if (this._elementRef.nativeElement.contains(target)) {
      if (!this.form.get('rating').value && !this.readOnly &&
        (this.ignoreGrading || this.grading.isDigitalGradingActive)) {
        this.showMarkInput$.next(true);
      }
    } else {
      this.showMarkInput$.next(false);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.marksOfRow?.currentValue?.length) {
      const currentRate = this.marksOfRow.find(mark => mark.type === this.generalAchievementValue);
      this.form.patchValue(currentRate, {emitEvent: false});
    }
    if (!this.marksOfRow?.length && changes.rate?.currentValue) {
      this.form.reset();

      if (this.rate.customMark !== null && this.rate.customMark !== undefined) {
        this.rate.customDigitalRating = this.rate.customMark;
      }

      this.form.patchValue(this.rate, {emitEvent: false});

      if (this.readOnly) {

        this.form.get('rating').disable({emitEvent: false});
      }
    }

    this._checkMarkInputVisibility();
  }

  ngOnInit(): void {
    this._handleAbsenseAllowance();
    this._trackFormChages();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub?.unsubscribe());
  }

  showPopover(): void {
    if (!this.readOnly && !this.isOpened) {
      const event: NusPopoverEvent = {
        rowId: this.rowId,
        colId: this.colId,
        hasComment: this.hasComment,
        grading: this.grading,
        rate: this.rate,
        form: this.form,
        additionalValues: this.additionalValues,
        generalAchievementValue: this.generalAchievementValue,
        cellRef: this._elementRef
      };

      if (this.isGeneralCharacteristics) {
        event.type = this.rate?.type;
      }

      this.openPopover.emit(event);
      this.isOpened = true;
    }
  }

  addEditComment(event: any): void {
    const values = this._getValues(this.form.value);
    const isEdit: boolean = !!values.description;
    values.description = event?.description;

    if (!values?.id) {
      this.markService.create(values).subscribe(() => {
        this._toast.success('Коментар додано');
        this._resetForm();
        this.submitted.emit();
      });
    } else {
      this.markService.update(values?.id, values).subscribe(() => {
        this._toast.success(isEdit ? 'Коментар збережено' : 'Коментар додано');
        this._resetForm();
        this.submitted.emit();
      });
    }
  }

  deleteComment(): void {
    const values = this._getValues(this.form.value);
    const controlWithValue = this._getControlsWithValue();
    values.description = null;

    if (!controlWithValue?.length) {
      this.markService.delete(values?.id).subscribe(() => {
        this._toast.success('Коментар видалено');
        this._resetForm();
        this.submitted.emit();
      });
    } else {
      this.markService.update(values?.id, values).subscribe(() => {
        this._toast.success('Коментар видалено');
        this._resetForm();
        this.submitted.emit();
      });
    }
  }

  private _handleAbsenseAllowance(): void {
    if (!this.isAbsenseSupported) {
      delete this.configControls.absense;
      this.form.removeControl('absense');
    } else {
      this.configControls.absense = {
        type: 'absense',
        controls: [
          {name: 'H', value: 0}
        ]
      };
    }
  }

  private _initForm(): void {
    this.form = new NitForm({
      id: new FormControl(),
      description: new FormControl(),
      rating: new FormControl()
    });

    const controls = Object.keys(this.configControls);
    for (const control of controls) {
      this.form.addControl(control, new FormControl());
    }
  }

  private _checkMarkInputVisibility(): void {
    this.showMarkInput$.subscribe((showMarkInput: boolean) => {
      this.isMarkInputHidden = !showMarkInput && !this.form.get('rating').value || !!this._getSelectedPopoverValue()
        || !this.form.get('rating').value && !this.grading.isDigitalGradingActive;
    });
    this.showMarkInput$.next(!!this.form.get('rating').value);
  }

  private _getValues(formValue: any): any {
    return Object.assign(formValue, {...this.additionalValues, [this.colKey]: this.colId, [this.rowKey]: this.rowId});
  }

  private _getControlsWithValue(): string[] {
    const controls: string[] = Object.keys(this.configControls);
    controls.push('rating');

    return controls.filter(control => this.form.get(control)?.value !== null);
  }

  private _submit(changeSource?: 'digital' | 'leveled' | 'verbal' | 'absent') {
    const controlsWithValue = this._getControlsWithValue();
    let redundantControls: string[] = [];
    const topicalFormValue = this.form.value;

    if ((this.ignoreGrading || this.grading.isDigitalGradingActive) && changeSource === 'digital') {
      redundantControls = controlsWithValue.filter(controlName =>
        
        controlName !== 'rating' && controlName !== 'customDigitalRating');
      topicalFormValue.leveledAssessmentType = null;
      topicalFormValue.isVerbalFormed = null;
    }

    if ((this.ignoreGrading || this.grading.isLeveledGradingActive) && changeSource === 'leveled') {
      redundantControls = controlsWithValue.filter(controlName =>
        controlName !== 'leveledAssessmentType');
      topicalFormValue.rating = null;
      topicalFormValue.customDigitalRating = null;
      topicalFormValue.isVerbalFormed = null;
    }

    if ((this.ignoreGrading || this.grading.isVerbalGradingActive) && changeSource === 'verbal') {
      redundantControls = controlsWithValue.filter(controlName =>
        controlName !== 'isVerbalFormed');
      topicalFormValue.rating = null;
      topicalFormValue.customDigitalRating = null;
      topicalFormValue.leveledAssessmentType = null;
    }

    if (changeSource === 'absent') {
      topicalFormValue.rating = null;
      topicalFormValue.customDigitalRating = null;
      topicalFormValue.isVerbalFormed = null;
      topicalFormValue.leveledAssessmentType = null;
    }

    const values = this._getValues(topicalFormValue);

    if (values.isVerbalFormed && this.generalAchievementValue != null) {
      delete values.isVerbalFormed;
      values.type = this.generalAchievementValue;
    }

    if (redundantControls?.length) {
      this.markService.delete(values?.id).subscribe(() => {
        this.markService.create(values).subscribe(() => {
          this._toast.success('Дані збережено');
          this._resetForm();
          this.submitted.emit();
        });
      });

      return;
    }

    if (!values?.id) {
      if (this.isGeneralCharacteristics && this.marksOfRow?.length && this.marksOfRow?.[0]?.type !== this.generalAchievementValue) {
        this.markService.update(this.marksOfRow?.[0]?.id, values).subscribe(() => {
          this._toast.success('Зміни збережено');
          this._resetForm();
          this.form.get('isVerbalFormed').setValue(null, {emitEvent: false});
          this.submitted.emit();
        });
      } else {
        this.markService.create(values).subscribe({
          next: () => {
            this._toast.success('Дані збережено');
            this._resetForm();
            this.submitted.emit();
          },
          error: () => {
            this._resetForm();
            this.submitted.emit();
          }
        });
      }
    } else {
      if (!controlsWithValue.length && !values.description) {
        this.markService.delete(values?.id).subscribe(() => {
          this._toast.success('Оцінку було видалено');
          this._resetForm();
          this.submitted.emit(this.rowId);
        });
      } else {
        this.markService.update(values?.id, values).subscribe(() => {
          this._toast.success(!controlsWithValue.length && values.description ? 'Оцінку було видалено' : 'Зміни збережено');
          this._resetForm();
          this.submitted.emit();
        });
      }
    }
  }

  private _resetForm(): void {
    this.form.get('id').patchValue(null, {emitEvent: false});
    this.form.get('description').setValue(null, {emitEvent: false});
    this.form.get('rating').setValue(null, {emitEvent: false});
    this.form.get('rating').enable({emitEvent: false});
    this.form.get('customDigitalRating').enable({emitEvent: false});
    this.form.get('leveledAssessmentType').enable({emitEvent: false});
    this.form.get('isVerbalFormed').enable({emitEvent: false});
  }

  private _trackFormChages(): void {
    const ratingSubscription = this.form?.get('rating').valueChanges.subscribe(val => {
      this.form?.get('customDigitalRating')?.setValue(null, {emitEvent: false});
      this.form?.get('isVerbalFormed')?.setValue(null, {emitEvent: false});
      this.form?.get('leveledAssessmentType')?.setValue(null, {emitEvent: false});

      if (this.isAbsenseSupported) {
        this.form?.get('absense')?.setValue(null, {emitEvent: false});
      }

      if (this.form.value.id || val !== null) {
        this.showMarkInput$.next(false);
        this._submit('digital');
      }
    });

    const customSubscription = this.form?.get('customDigitalRating')?.valueChanges.subscribe(val => {
      this.form?.get('rating')?.setValue(null, {emitEvent: false});
      this.form?.get('isVerbalFormed')?.setValue(null, {emitEvent: false});
      this.form?.get('leveledAssessmentType')?.setValue(null, {emitEvent: false});

      if (this.isAbsenseSupported) {
        this.form?.get('absense')?.setValue(null, {emitEvent: false});
      }

      if (this.form.value.id || val !== null) {
        this.showMarkInput$.next(false);
        this._submit('digital');
      }
    });

    if (this.isAbsenseSupported) {
      const absenseSubscription = this.form?.get('absense')?.valueChanges?.subscribe(val => {
        this.form?.get('rating')?.setValue(null, {emitEvent: false});
        this.form?.get('customDigitalRating')?.setValue(null, {emitEvent: false});
        this.form?.get('isVerbalFormed')?.setValue(null, {emitEvent: false});
        this.form?.get('leveledAssessmentType')?.setValue(null, {emitEvent: false});

        if (this.form.value.id || val !== null) {
          this._submit('absent');
        }
      });
      this.subscriptions.push(absenseSubscription);
    }

    const verbalSubscription = this.form?.get('isVerbalFormed').valueChanges.subscribe(val => {
      this.form?.get('customDigitalRating')?.setValue(null, {emitEvent: false});
      this.form?.get('rating')?.setValue(null, {emitEvent: false});
      this.form?.get('leveledAssessmentType')?.setValue(null, {emitEvent: false});
      this.form?.get('absense')?.setValue(null, {emitEvent: false});

      if (this.form.value.id || val !== null) {
        this.showMarkInput$.next(false);
        this._submit('verbal');
      }
    });

    const leveledSubscription = this.form?.get('leveledAssessmentType').valueChanges.subscribe(val => {
      
      this.form?.get('customDigitalRating')?.setValue(null, {emitEvent: false});
      this.form?.get('rating')?.setValue(null, {emitEvent: false});
      this.form?.get('isVerbalFormed')?.setValue(null, {emitEvent: false});
      this.form?.get('absense')?.setValue(null, {emitEvent: false});

      if (this.form.value.id || val !== null) {
        this.showMarkInput$.next(false);
        this._submit('leveled');
      }
    });
    this.subscriptions.push(leveledSubscription, verbalSubscription, customSubscription, ratingSubscription);
  }

  private _getSelectedPopoverValue(): string {
    const controls = Object.keys(this.configControls);
    const selectedControlName = controls.find(x => this.form.get(x)?.value !== null);
    if (!selectedControlName) return;

    return this.configControls[selectedControlName]?.controls?.find(x =>
      this.form.get(selectedControlName)?.value === x.value)?.name;
  }
}
